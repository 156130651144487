import { Injectable } from '@angular/core'
import { Subscription } from 'rxjs'

import Graphic from '@arcgis/core/Graphic'
import Map from '@arcgis/core/Map'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import MapView from '@arcgis/core/views/MapView'
import ZoomVM from '@arcgis/core/widgets/Zoom/ZoomViewModel'
import SnappingControls from '@arcgis/core/widgets/support/SnappingControls'

import { Geometry } from '@arcgis/core/geometry'

import Compass from '@arcgis/core/widgets/Compass'
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel'

import { AppConfigService } from '@service/app-config/app-config.service'

import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  saveMapView,
  setMapConfigErrorReason,
  showDeleteSchoolPerimeterBoundaryConfirmation
} from '@action/school-map-config-page.actions'
import {
  updateAreaStatusByAreaIdToDtoLookup,
  updateAreaWithResponsePayload
} from '@action/user/dashboard-page.action'
import { MapConfigEditAreaPopupComponent } from '@component/map-config-edit-area-popup/map-config-edit-area-popup.component'
import { MapPopupComponent } from '@component/shared/map-popup/map-popup.component'
import { ChatMessageDtoHelper } from '@domain/dto-helpers/message.helper'
import { DecoratedAlertViewModel } from '@model/alert.model'
import { CoordinateCollection } from '@model/location.model'
import {
  ChatMessageDto,
  ChatMessageDtoWithUserIdsPayload,
  ChatResponseDtoPayload,
  ChatResponseWithMessagePayload,
  ChatResponseWithPollResponseIdPayload
} from '@model/message.model'
import { GetResponseDto, PredefinedMessageHelper } from '@model/message/predefined-message.model'
import { ResponseGroupTypeEnum } from '@model/message/response-group.model'
import { SchoolStateEnum } from '@model/school/school-configuation.model'
import { GetSchoolAreaDto, SchoolAreaDto } from '@model/school/school-subarea.model'
import { GetSchoolDto, SchoolDtoProps } from '@model/school/school.model'
import { DecoratedUserLocationViewModel } from '@model/user/user-location.model'
import { MobileUserTypes } from '@model/user/user.model'
import { Store } from '@ngrx/store'
import { selectAreaWithStatusData } from '@selector/page/dashboard-page/dashboard-map.selectors'
import { selectAllLoadedDashboardPageState } from '@selector/page/dashboard-page/dashboard-page-state.selectors'
import {
  selectCurrentDimmedAttackAlertState,
  selectDashboardVmLocationVisibility
} from '@selector/page/dashboard-page/dashboard-view.selectors'
import { selectCurrentProximitySliderValue } from '@selector/page/school-map-config-page/school-map-config-page-state.selector'
import { LocalStorageKeys, SessionStorageKeys } from '@service/browser/base-storage.service'
import { LocalStorageService } from '@service/browser/local-storage.service'
import { SessionStorageService } from '@service/browser/session-storage.service'
import { userDefaultSideNavSettings } from '@shared/constants'
import { GlobalState } from '@state/auth.state'
import { DashboardPageState } from '@state/page/dashboard-page.state'
import { BaseMapToggleItemViewModel } from '@view/map/base-layer/base-layer-toggle.view'
import {
  ILocationVisibilityViewModel,
  LocationsForDashMapViewModel
} from '@view/map/locations/map-control-ui.view'
import { MapViewModel } from '@view/map/map.view'
import { MapConfigToolbarOptions } from '@view/map/school-map-config/school-map-config-toolbar.view'
import { IMapUiControlSessionSettings } from '@view/pages/dashboard-page/dashboard-page.view'
import { MapConfigMapViewStepViewModel } from '@view/pages/school-map-config-page/map-config-map-view-step.view'
import {
  IMapConfigViewModel,
  ISchoolMapConfigStepViewModel,
  MapConfigSteps
} from '@view/pages/school-map-config-page/school-map-config.view'
import { MapConfigValidationErrors } from '@view/pages/school-map-config-page/school-map-config-validation.view'
import { MapPageType } from '../map.component.service.model'
import { BaseMapService } from '../map.service.model'
import { OwlThreatModel } from '../threat-indicator.model'
import { ArcGisAlertHandler } from './alert/arcgis-alert.view'
import { ArcGisPointFactory } from './arcgis-point-factory'
import { ArcGisSymbolFactory } from './arcgis-symbol-factory.view'
import { ArcgisAreaGraphicHandler } from './area/arcgis-area-symbol.view'
import { ArcgisBoundaryViewModel } from './area/arcgis-boundary.view'
import { ArcGisFilterByTimestampAttribute } from './attributes/arcgis-timestamp-attribute-filter.view'
import { ArcGisMouseWheelHandler } from './cursor/arcgis-mouse-wheel.view'
import { ArcGisGeometryHelper } from './geometry/arcgis-geometry.view'
import { ArcGisPollResponseLocGraphicHandler } from './graphic/poll-response-location-graphic.view'
import { ArcgisLayersHandler } from './layers/arcgis-layers-handler.view'
import { ArcGisMapConfigStateHandler } from './map-config/arcgis-school-map-config-vm-handler.view'
import { ArcGisMessageLocHandler } from './message-locations/arcgis-message-location.view'
import { ArcGisHomeViewModel } from './school-dto/arc-gis-handle-home.view'
import { ArcGisSaveMapConfigHandler } from './school-dto/arc-gis-handle-save-map-config.view'
import { ArcGisHandleSchoolDtoInit } from './school-dto/arc-gis-handle-school-dto-init.view'
import { ArcGisSketchViewModelEventHandler } from './sketch/arc-gis-sketch-vm-event-handler.view'
import { ArcGisSketchCustomToolClickHandler } from './sketch/custom-tool-component/arc-gis-sketch-custom-tool-click-handler.view'
import { ArcGisSketchCustomToolStateHandler } from './sketch/custom-tool-component/arc-gis-sketch-custom-tool-state-handler.view'
import { ArcGisLocationHandler } from './user-locations/arcgis-user-location.view'
import { ArcGisLocationsHandler } from './user-locations/arcgis-user-locations.view'
import { toggleZoomVisibility } from './zoom/zoom-visibility.view'
import { updateThreatModelEventLoop } from '@action/dashboard-page.actions'
import { OwlLoggingArea, OwlLogLevels } from '@service/logging/logging.model'
import { LoggingService } from '@service/logging/logging.service'

export enum LayerIdToTypeLookup {
  area = 'area',
  studentLocation = 'studentLocation',
  guestLocation = 'guestLocation',
  teacherLocation = 'teacherLocation',
  otherStaffLocation = 'otherStaffLocation',
  pulsingAlert = 'pulsingAlert',
  medicalAlert = 'medicalAlert',
  dimmedAlert = 'dimmedAlert',
  attackAlert = 'attackAlert'
}
@Injectable()
export class ArcGisMapService extends BaseMapService<Map> {
  static widgetPlacement = 'bottom-right'
  //Recalc loop
  threatModel: OwlThreatModel | null = null
  // NodeJS.Timeout | null = null
  interval: any = null
  //Local State to Optimize mouse events
  historicChatMessagesAdded: boolean = false
  // hover
  lastHoveredUserType: MobileUserTypes | null = null
  lastHoveredUserId: string | null = null
  lastHoveredAreaId: string | null = null
  // click
  lastClickedUserType: MobileUserTypes | null = null
  lastClickedUserId: string | null = null
  lastClickedAreaId: string | null = null
  lastClickedAlertId: string | null = null

  dashSubscriptions: Subscription[] | null = null
  mapConfigSubscriptions: Subscription[] | null = null

  /** Save a projected version of every area for later intersection calculations in real time, used for location opacity. */
  projectedAreaPolygonGeomLookups: Record<number, Geometry> = {}

  /** @deprecated TODO Remove usage  There is a manual and expensive calculation to filter down locations by a selected area in emergency mode so track when we do that so we don't do it again unnecessarily */
  filterLocationsByBoundary: number[][] | null = null

  /** When we select an area we update symbols and update each graphics attributes with the opacity indicator, saved here to be used when receiving real time data */
  opaqueUserIds: Record<string, boolean> = {}

  //Map Config
  proximitySliderValue$ = this.store.select(selectCurrentProximitySliderValue)

  //Historic - selector waits until all dependencies are loaded over network before processing historic data
  selectDashboardPageState$ = this.store.select(selectAllLoadedDashboardPageState)

  //Real time
  schoolAreasWithAllRelatedData$ = this.store.select(selectAreaWithStatusData)
  userTypeLayerVisibility$ = this.store.select(selectDashboardVmLocationVisibility)
  dimmedAlertGraphicsLayerVisibility$ = this.store.select(selectCurrentDimmedAttackAlertState)

  //Service view model to update from page state
  vm: MapViewModel = new MapViewModel()

  /** Sync ref to map config validation for async event handlers */
  mapConfigValidationError: MapConfigValidationErrors | null = null
  /** Sync ref to active step in state for async event handlers */
  activeConfigStep: MapConfigSteps | null = null

  /** Might be osm boundary or from the database so track explicitly */
  _schoolBoundaryGraphic?: Graphic

  _layerTypeToIdLookup: Record<LayerIdToTypeLookup, string | null> = {
    [LayerIdToTypeLookup.area]: null,
    [LayerIdToTypeLookup.pulsingAlert]: null,
    [LayerIdToTypeLookup.medicalAlert]: null,
    [LayerIdToTypeLookup.attackAlert]: null,
    [LayerIdToTypeLookup.dimmedAlert]: null,
    // [LayerIdToTypeLookup.location]: null,
    [LayerIdToTypeLookup.studentLocation]: null,
    [LayerIdToTypeLookup.guestLocation]: null,
    [LayerIdToTypeLookup.teacherLocation]: null,
    [LayerIdToTypeLookup.otherStaffLocation]: null
  }

  schoolDto?: GetSchoolDto
  // userManuallyCreatedBoundary: boolean = false
  /** Since the user can edit a boundary, we need a sync ref to the latest value in order to update this */
  _syncRefToProximitySliderValue: number | null = null
  /** Since at some point we'll be reimplementing all the map features in a backup mapping api, keep the type generic between mapping providers */
  _customBaseLayerOptionVm: BaseMapToggleItemViewModel | null = null
  popupRef: MapPopupComponent | null = null
  mapConfigPopupRef: MapConfigEditAreaPopupComponent | null = null
  userTypeLayerVisibilityVm?: ILocationVisibilityViewModel
  historicSub?: Subscription
  osmRecommendedBoundary: CoordinateCollection | null = null

  //TODO Move to page state`  after ngrx integration
  get uploadingImage(): boolean {
    return false
  }

  setEditMode(): void {}
  setSelectShapes(): void {}
  setImageUploadMode(): void {
    // this._addUploadImageContainer()
  }
  setDrawMode() {}
  setSelectionMode() {}
  handlePolygonTypeChange() {
    throw new Error('Method not implemented.')
  }
  _zoomVm?: ZoomVM
  _compass?: Compass
  _snappingControls?: SnappingControls
  _sketchViewModel?: SketchViewModel
  // IHandle - no
  _sketchViewModelEventHandles: __esri.Handle[] = []
  /** Default value is overwritten to reduce number of conditional chaining operators needed, TODO Consider same pattern with sketch view model */
  _mapView = new MapView()

  //LAYERS
  //School boundary and area
  _schoolBoundaryLayer?: GraphicsLayer
  /** When we  */
  _schoolBoundaryProximityLayer?: GraphicsLayer
  _schoolAreaLayer?: GraphicsLayer
  //Alert Layers
  _pulsingAlertGraphicsLayer?: GraphicsLayer
  _attackAlertGraphicsLayer?: GraphicsLayer
  _dimmedAlertGraphicsLayer?: GraphicsLayer
  _medialAlertGraphicsLayer?: GraphicsLayer
  _threatLocationLayer?: GraphicsLayer

  //User Location Layers
  _studentLocationLayer?: GraphicsLayer
  _teacherLocationLayer?: GraphicsLayer
  _otherStaffLocationLayer?: GraphicsLayer
  _guestLocationLayer?: GraphicsLayer

  //User Recent Message Layers
  _studentRecentMessageLayer?: GraphicsLayer
  _teacherRecentMessageLayer?: GraphicsLayer
  _otherStaffRecentMessageLayer?: GraphicsLayer
  _guestRecentMessageLayer?: GraphicsLayer

  //User Poll Response Location Layers by type
  //todo add location after responses for layer name
  _studentPollResponsesLayer?: GraphicsLayer
  _teacherPollResponsesLayer?: GraphicsLayer
  _otherStaffPollResponsesLayer?: GraphicsLayer
  _guestPollResponsesLayer?: GraphicsLayer
  //User Message Location Layers by type
  _studentMessageLocationLayer?: GraphicsLayer
  _teacherMessageLocationLayer?: GraphicsLayer
  _otherStaffMessageLocationLayer?: GraphicsLayer
  _guestMessageLocationLayer?: GraphicsLayer

  /**Admin Map Config Layers */
  _sketchGraphicsLayer?: GraphicsLayer
  _uploadedImageLayer = new GraphicsLayer({
    opacity: 0.5
  })

  constructor(
    public _appConfig: AppConfigService,
    public store: Store,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private loggingService: LoggingService
  ) {
    super(store)
    window.addEventListener('storage', this._handleStorageEvent)
  }

  destroy = () => {
    this.log(`arcgis map service destroy type: ${this.type}`)
    if (this.type === MapPageType.schoolMapConfig) {
      ArcGisSketchViewModelEventHandler.removeEventHandlers(this)
      this.activeConfigStep = null
      this.mapConfigSubscriptions?.forEach((s) => s.unsubscribe())
    }
    if (this.type === MapPageType.schoolDashboard) {
      //this.threatModel = null
      this.projectedAreaPolygonGeomLookups = {}
      this.dashSubscriptions?.forEach((s) => s.unsubscribe())
      this.historicSub?.unsubscribe()
    }
    this._map?.destroy()
    this._map = undefined
    this._mapView?.destroy()
    this.schoolDto = undefined
    this._schoolBoundaryLayer?.destroy()
    this._schoolBoundaryLayer = undefined
    this._schoolAreaLayer?.destroy()
    this._schoolAreaLayer = undefined
    this._sketchViewModel?.destroy()
    this._sketchViewModel = undefined
    this.baseSubs.forEach((s) => s.unsubscribe())
    this.interval && clearInterval(this.interval)
    window.removeEventListener('storage', this._handleStorageEvent)
  }
  _handleStorageEvent = (
    //https://developer.mozilla.org/en-US/docs/Web/API/StorageEvent
    storageEvent: StorageEvent
  ): void => {
    if (storageEvent.key === LocalStorageKeys.pulsingAlerts) {
      const { newValue } = storageEvent
      //Iterate over all pulsing alerts on map and remove any not in this updated string
      this._pulsingAlertGraphicsLayer?.graphics.forEach((g) => {
        if ((newValue?.indexOf(g.attributes.chatResponseDtoPayload.logicalId) ?? 0) > -1) {
          this.log('pulsing graphic removal from layer, attributes:', g.attributes)
          // console.info(`Removing pulsing alert from action in other tab with id ${g.attributes.id}`)
          this._pulsingAlertGraphicsLayer?.remove(g)
        }
      })
    }
  }
  override _handleMapConfigVmUpdates = (vm: IMapConfigViewModel | null) => {
    ArcGisMapConfigStateHandler.handleMapConfigVmChange(this, vm)
  }
  public setPopup = (popup: MapPopupComponent | MapConfigEditAreaPopupComponent): void => {
    if (popup instanceof MapPopupComponent) {
      this.popupRef = popup
    } else if (popup instanceof MapConfigEditAreaPopupComponent) {
      this.mapConfigPopupRef = popup
    }
  }
  /** Global handling of auth state to set up map for school map dashboard as well as map config, with internal handling by context @property type */
  override _initBasedOnSchoolDto = (authState: GlobalState | null) => {
    ArcGisHandleSchoolDtoInit.handleAuthSelectedSchoolDtoInit(this, authState)
    this.handleSideNavSessionStorage()
  }
  /** Isolated function to react to when the selected area is changed or deselected. */
  handleSelectArea = (payload: number | null) => {
    ArcGisLocationsHandler.handleSelectedArea(this, payload)
  }
  /** Central place where historic processing of dashboard related map data starts, subscription is to all dash state when all dependencies are loaded. */
  handleDashPageStateUpdate = (s: DashboardPageState | null): void => {
    if (!s) {
      return
    }
    if (this.historicChatMessagesAdded) {
      this.log(
        `Unsubscribing from historic data selector after loading page state update as now historic data is added to map.`
      )
      if (this.historicSub) {
        this.historicSub?.unsubscribe()
      }
      return
    }
    const vm = LocationsForDashMapViewModel.getChatMessageLocationsWithLookups(s)
    if (!vm) {
      return
    }

    // HANDLE AREA STATUSES
    /** This handles the geo query for area status calculation */
    this._handleUserMobileToResponseGeoQuery(s)

    //Threat Model
    if (s.appConfig) {
      this.threatModel = new OwlThreatModel(s.appConfig.THREAT_MODEL_CONFIG)
      // Once we process historic data we can start the event loop for dimming alerts and recalulating threat indicators
      const threatModelUpdateInterval =
        s.appConfig.THREAT_MODEL_CONFIG.THREAT_AREA_UPDATE_INTERVAL_SECONDS * 1000
      // TODO This needs to be rethought because we trigger the threat model event loop even if there's no threat vms which is a bit wasteful but feature was written in haste
      this.interval = setInterval(
        () => this.store.dispatch(updateThreatModelEventLoop()),
        threatModelUpdateInterval
      )
    } else {
      console.warn(`DEV ERROR: Threat model not instantiated for arg gis map service!`)
    }

    // HANDLE LOCATIONS
    // Must be done after the threat model is set up as it uses the threat model
    //Both the historic processing and later event loop init require the threat model to exist so this must be in order here
    ArcGisMessageLocHandler.handleHistoricChatMessageLocation(
      this,
      vm,
      this.localStorageService.getStringValue(LocalStorageKeys.pulsingAlerts)
    )
    //Set indicator to avoid state recalculation
    this.historicChatMessagesAdded = true
  }
  /**
   *  Handles syncing the arc gis map instance with layer visibility as set by settings
   */
  handleSideNavSessionStorage = () => {
    // Session storage state for map
    const settings: IMapUiControlSessionSettings | null | undefined =
      this.sessionStorageService.getKey(SessionStorageKeys.userSideNavSettingsKey)
    if (settings) {
      ArcgisLayersHandler.handleSessionSavedSettings(this, settings)
    } else {
      ArcgisLayersHandler.handleSessionSavedSettings(this, userDefaultSideNavSettings)
    }
  }
  /** Updates threat area and any dimmable alerts based on age of messages */
  updateThreatModelEventLoop = (responseIdToGetResponseDto: Record<number, GetResponseDto>) => {
    // Only handle the event loop while on the dashboard page
    // TODO Remove this after we clear the interval on destroy
    if (this.type !== MapPageType.schoolDashboard) {
      return
    }
    this.log(`Calling alert related event loop!`)
    if (!this.threatModel) {
      console.warn(
        `this.threatModel not defined in arc gis map service, it's required for the alert and threat indicator event loop.`
      )
      return
    }
    const removedIds = this.threatModel.reprocessThreatVmsForEventLoop(
      this,
      responseIdToGetResponseDto
    )
    ArcGisAlertHandler.updateDimmedThreatMapGraphics(this, removedIds)
  }
  _handleFilterMapContentByStaleDataDate = (d: Date, state: DashboardPageState) => {
    if (d) {
      ArcGisFilterByTimestampAttribute.filterAllChatMessageDtoDataOnMapByDate(this, d, state)
      if (this.threatModel) {
        this.threatModel.clearStaleThreatVmsAndUpdate(d, this)
      } else {
        console.warn(`DEV ERROR: Threat model not constructed for arc gis map service!`)
      }
    }
  }
  _handleConcludeEvent = () => {
    ArcGisFilterByTimestampAttribute.handleConcludeEvent(this)
  }

  _handleRemoveLocation = (mobileUserId: string, state: DashboardPageState) => {
    let vm: DecoratedUserLocationViewModel | null = state.userLocationLookup[mobileUserId] ?? null
    if (!vm) {
      console.warn(
        `DEV ERROR: handleRemoveLocationVm: No vm found for mobile user id ${mobileUserId} in arc gis map service!`
      )
      return
    }
    ArcGisLocationHandler.handleRemoveLocationVm(this, vm)
  }

  _handleNewLocationUpdate = (
    mobileUserId: string,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ): void => {
    let vm: DecoratedUserLocationViewModel | null = state.userLocationLookup[mobileUserId] ?? null
    if (!vm) {
      console.warn(
        `handleNewLocationUpdate: No vm found for mobile user id ${mobileUserId} in arc gis map service!`
      )
      return
    }
    let selectedUserId = state.vm.selectedUserMobileId
    ArcGisLocationHandler.handleLocationOpacityLogic(this, mobileUserId, state, schoolOwlState)
    ArcGisLocationHandler.handleLocationVm(this, vm, selectedUserId)
  }
  _handleRealTimeSertResponse = (
    state: DashboardPageState,
    payload: ChatMessageDtoWithUserIdsPayload
  ) => {
    ArcGisMessageLocHandler._handleSertResponse(this, state, payload)
  }
  _handleRealTimeMobileResponse = (
    dto: ChatMessageDto,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ) => {
    const {
      userToTypeLookup,
      responseIdToGetResponseDto,
      responseIdsByResponseGroupTypeLookup,
      mobileIdToDisplayNameLookup
    } = state
    this.log(`responseIdsByResponseGroupTypeLookup`, responseIdsByResponseGroupTypeLookup)
    const responseIdsConsideredSos =
      responseIdsByResponseGroupTypeLookup[ResponseGroupTypeEnum.alert]
    const responseIdsCondsideredMedicalAlert =
      responseIdsByResponseGroupTypeLookup[ResponseGroupTypeEnum.medicalAlert]
    let alertPayload: ChatResponseWithPollResponseIdPayload | null = null
    if (!ChatMessageDtoHelper.isChatResponseDto(dto)) {
      console.error(`DEV ERROR: Chat message payload passed to map chat response handler!`)
      return
    }
    const payload = dto.payload
    let isThreatIndicator: boolean | null = null
    let isAlert: boolean | null = null
    let isBalconyAlert: boolean | null = null
    let isMedicalAlert: boolean | null = null
    let hasResponseId: boolean | null = null
    let getResponseDto: GetResponseDto | null = null
    if (ChatMessageDtoHelper.isChatResponseResponseIdPayload(payload)) {
      hasResponseId = true
      getResponseDto = responseIdToGetResponseDto[payload.responseId]
    }
    //To consider something an alert it must either be in that category of alert responses, checked here, or have a threat indicator related response id, checked later
    if (ChatMessageDtoHelper.isSolicitedAlertPayload(dto, responseIdsConsideredSos)) {
      isAlert = true
    }
    if (dto.payload.isSos && !dto.payload.responseId) {
      isBalconyAlert = true
    }
    if (getResponseDto && PredefinedMessageHelper.isThreatIndicator(getResponseDto.type)) {
      // In order to process a threat indicator we must have a response marked by that type
      isThreatIndicator = true
      //An alert can be an unsolicited message or a poll response so we have to check for the response id to type lookup
      isAlert = true
    } else if (
      ChatMessageDtoHelper.isSolicitedMedicalAlertResponse(dto, responseIdsCondsideredMedicalAlert)
    ) {
      isMedicalAlert = true
    }
    //Threat graphic may be sourced from an alert or a specfic type of poll response, handled internally for payload
    if (!this.threatModel) {
      console.warn(`DEV ERROR: Threat model not instantiated for arg gis map service!`)
      return
    }
    // While an any response id can be marked as a threat indicator alerts, medical alerts, and poll responses are mutually exclusive
    if (isThreatIndicator || isBalconyAlert) {
      this.log(`Adding threat indicator for response id`, { responseId: payload.responseId })
      this.threatModel.updateThreatGraphicWithPayload(this, payload, responseIdToGetResponseDto)
    }
    if (isAlert || isMedicalAlert) {
      const alertVm = {
        mobileUserId: dto.payload.mobileUserId,
        userType: userToTypeLookup[dto.payload.mobileUserId ?? '0'],
        fullName: mobileIdToDisplayNameLookup[dto.payload.mobileUserId ?? '0'],
        chatResponseDtoPayload: dto.payload,
        response: responseIdToGetResponseDto[dto.payload.responseId ?? 0]
      } as DecoratedAlertViewModel

      if (isAlert) {
        this.log(`Adding alert and pulsing alert graphic for response id`, {
          responseId: payload.responseId
        })
        // Add the alert graphic
        ArcGisMessageLocHandler.addAlertPoint(this, payload, true, alertVm)
        // Add the pulsing alert graphic
        ArcGisMessageLocHandler.addAlertPoint(this, payload, false, alertVm)
      } else if (isMedicalAlert) {
        this.log(`Adding medical alert response id`, { responseId: payload.responseId })
        this._handleMedicalEmergencyResponse(dto.payload, alertVm)
      }
    } else if (ChatMessageDtoHelper.isPollResponseWithPollIdDto(dto)) {
      ArcGisMessageLocHandler._handleRealTimeChatResponsePollResponse(
        this,
        dto.payload,
        state,
        schoolOwlState
      )
    }
  }
  _handleRealTimeChatResponseReceivedMessageDto = (
    payload: ChatResponseWithMessagePayload,
    lookup: Record<string, MobileUserTypes>
  ) => {
    ArcGisMessageLocHandler._handleRealTimeChatResponseReceivedMessageDto(this, payload, lookup)
  }
  /**
   * @param state - The current state of the dashboard page is used to process the user chat response by mobile id lookups and do geospatial query for each object to determine if it's in an area.
   * @fires action with a payload of all dtos grouped by which area they're in so that we can calculate the area statuses in the area view model.
   *  1. non alert related message includes only the last response from each user
   *  2. negating alerts include all the negated alerts for that mobile guid aka logical id
   *  3. all alerts by mobile logical id
   */
  _handleUserMobileToResponseGeoQuery = (state: DashboardPageState) => {
    if (!this._schoolAreaLayer) {
      console.warn(`Area layer not instantiated can't handle poll responses.`)
      return
    }
    // TODO Fix this as it's getting called twice during historic data processing.
    this.log(`_handleUserMobileToResponseGeoQuery`, state)
    const {
      responseIdToGetResponseDto,
      userMobileIdToLastPollResponseLookup,
      userMobileIdToAlerts,
      userMobileIdToNegateAlerts
    } = state
    const areaIdToUsersLastPollResponses = ArcGisGeometryHelper.returnResponseByArea(
      userMobileIdToLastPollResponseLookup,
      this._schoolAreaLayer,
      responseIdToGetResponseDto
    )
    // Pass in the threat model config so we can skip processing dimmed alerts
    const areaIdToAlerts = ArcGisGeometryHelper.returnResponseByArea(
      userMobileIdToAlerts,
      this._schoolAreaLayer,
      responseIdToGetResponseDto
    )
    const areaIdToNegatedAlerts = ArcGisGeometryHelper.returnResponseByArea(
      userMobileIdToNegateAlerts,
      this._schoolAreaLayer,
      responseIdToGetResponseDto
    )

    const payload = { areaIdToAlerts, areaIdToUsersLastPollResponses, areaIdToNegatedAlerts }
    this.log(`Geo query result of historic messages`, payload)
    this.store.dispatch(updateAreaStatusByAreaIdToDtoLookup(getExtendedAction(payload)))
  }
  /** Takes in a mobile user chat response by mobile id lookup and dispatches an action to update an area id to area view model action. */
  _handleRealTimeResponseGeospatialQuery = (payload: ChatResponseDtoPayload) => {
    if (!this._schoolAreaLayer) {
      console.warn(`Area layer not instantiated can't handle poll responses.`)
      return
    }
    let areaIdForPayload: number | null = null
    //Check if the payload is in an area
    const point = ArcGisPointFactory.getPointFromChatRes(payload)
    if (!point) {
      console.warn(`Point not found for chat response payload!`)
      return
    }
    areaIdForPayload = ArcGisGeometryHelper.getAreaIdForPoint(this._schoolAreaLayer, point)
    if (areaIdForPayload) {
      this.store.dispatch(
        updateAreaWithResponsePayload(getExtendedAction({ payload, areaIdForPayload }))
      )
    }
  }
  _handleMedicalEmergencyResponse = (
    payload: ChatResponseDtoPayload,
    alertVm: DecoratedAlertViewModel
  ) => {
    ArcGisPointFactory.addMedicalAlertResponseToMap(this, payload, alertVm)
  }
  _handlZoomChange = async (zoom: number) => {
    ArcGisMouseWheelHandler.handleDashboardWheelChange(this, {} as __esri.ViewMouseWheelEvent)
    if (zoom < this._appConfig.config.ZOOM_LEVEL_VISIBILITY) {
      toggleZoomVisibility(this, false)
    } else {
      toggleZoomVisibility(this, true)
    }
  }
  toggleBaseLayer = (payload: BaseMapToggleItemViewModel) => {
    if (this._map) {
      this._map.basemap = payload.arcGisLayer as any
      this._customBaseLayerOptionVm = payload
    }
  }
  /** When we save a school we also need to potentially deselect the area */
  dispatchSaveSchool = () => {
    ArcGisSaveMapConfigHandler.saveSchool(this)
    //Once we save the shape, deselect it to prep for which ever step user is moving to.
    ArcGisSketchViewModelEventHandler.deselectPerimeterBoundary(this)
  }
  updateSchoolAreaDtoSuccess = (dto: GetSchoolAreaDto): void => {
    ArcgisAreaGraphicHandler.updateAttributesWithNewDto(this, dto)
  }
  handleMapConfigToolbarClick = (o: MapConfigToolbarOptions) => {
    ArcGisSketchCustomToolClickHandler.handleCustomToolClick(this, o)
  }
  /** On successful save of the boundary, revert the graphic back to the display symbol */
  handlePatchSchoolSuccess = (dto: GetSchoolDto) => {
    // We'll be on the add areas step when this side effect handler is invoked since they are processed after the reducer which updates the step
    if (this.activeConfigStep === MapConfigSteps.schoolPerimeter) {
      const schoolBoundary = dto[SchoolDtoProps.boundary] ?? []
      const noSavedBoundary = schoolBoundary.length === 0
      const userSketchedBoundary = this?._schoolBoundaryLayer?.graphics.at(0)
    } else if (this.activeConfigStep === MapConfigSteps.addAreas) {
      // Reset the graphic in case it's left in edit state
      const schoolBoundary = this._schoolBoundaryLayer?.graphics.at(0)
      if (schoolBoundary) {
        schoolBoundary.symbol = ArcGisSymbolFactory.displaySchoolMapConfigAreaSymbol.clone()
      }
      //Hide the proximity location buffer indicator
      ArcgisBoundaryViewModel.toggleProximityLayer(this, false)
      // On navigating back to a completed areas step be sure to enable forward navigate
      const userAreas = this.schoolDto?.[SchoolDtoProps.subareas] ?? []
    }
  }
  /** Handle adding the area from the post to the map and popup config */
  handlePostAreaDtoSuccess = (dto: SchoolAreaDto) => {
    // No need to handle post area side effect here, we receive the new updated school dto and look for any area's without ids on the map and update accordingly
    //TODO Verify the above and remove function
  }
  /** Handle removing the area from the sketvh view model. */
  deleteAreaFromMapConfig = () => {
    if (this._sketchViewModel) {
      this._sketchViewModel.delete()
      ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(this, null)
      this.mapConfigPopupRef?.hidePopup() //If we just deleted an area and it wasn't valid then we have to revert the validation state as a side effect
    }
    // If user deleted last area, prevent cta button click
    const lastAreaDeleted = this.schoolDto?.[SchoolDtoProps.subareas]?.length === 0
    if (lastAreaDeleted) {
      this.store.dispatch(
        setMapConfigErrorReason(
          getExtendedAction(MapConfigValidationErrors.atLeastOneAreaMustExist)
        )
      )
    } else if (this.mapConfigValidationError) {
      this.store.dispatch(setMapConfigErrorReason(getExtendedAction(null)))
    }
  }
  /** Check if the user left a boundary before invoking nav back logic, if it's deleted we'll send a network call to delete it on navigation with a confirmation model but if user goes back and hasn't set up a boundary at all and there's not one saved, then we let them go back to the map view steps */
  // handleNavigateBackwardFromPerimeterStep = () => {
  //   const hasSavedButDeletedBoundary = ArcgisBoundaryViewModel.hasSavedButDeletedBoundary(this)
  //   const hasDrawnBoundaryOnMap = ArcgisBoundaryViewModel.hasDrawnBoundaryOnMap(this)
  //   //Be sure to handle side effects before dispatching to avoid race conditions on the sketch vm construction
  //   if (hasDrawnBoundaryOnMap) {
  //     ArcGisSketchViewModelEventHandler.cancelCreateInProgressIfExists(this)
  //     ArcGisSketchViewModelEventHandler.deselectPerimeterBoundary(this)
  //     ArcgisBoundaryViewModel.toggleProximityLayer(this, false)
  //   }
  //   if (hasSavedButDeletedBoundary) {
  //     this.store.dispatch(showDeleteSchoolPerimeterBoundaryConfirmation())
  //   }
  // }
  handleCompleteMap = () => {
    ArcGisSketchViewModelEventHandler.deselectAreaEditIfSelected(this)
  }
  /** Slider uses config max PROXIMITY_DISTANCE_IN_FEET if not in school dto*/
  handleProximityBoundaryChange = (v: number) => {
    this._syncRefToProximitySliderValue = v
  }
  engageMapConfigSlider = (v: number) => {
    this.handleProximityBoundaryChange(v)
    //Deselect boundary if selected and set the initial value of the expanded boundary
    ArcGisSketchViewModelEventHandler.cancelCreateInProgressIfExists(this)
    ArcGisSketchViewModelEventHandler.deselectPerimeterBoundary(this)
    ArcgisBoundaryViewModel.setBoundaryProximityIfExists(this)
    //In case the sketch vm has undo redo history we just reset, update the tool to indicate updated state
    ArcGisSketchCustomToolStateHandler.handleUpdateCustomToolsVm(this, null)
  }
  updateMapConfigProximitySlider = (v: number) => {
    this.handleProximityBoundaryChange(v)
    ArcgisBoundaryViewModel.setBoundaryProximityIfExists(this)
  }
  /** When user changes the rotation slider, sync the map rotation with that. */
  updateRotationSlider = (v: number) => {
    this._mapView.rotation = v
  }
  /** Arrange the map view save view model from the arc gis map view instance for save in db */
  handleSaveMapView = () => {
    if (!this._map) {
      throw Error(`No map available to save!`)
    }
    if (!this._customBaseLayerOptionVm || !this?._customBaseLayerOptionVm?.option) {
      throw Error(`No _customBaseLayerOptionVm available to save!`)
    }
    const payload: MapConfigMapViewStepViewModel = {
      baseLayer: this._customBaseLayerOptionVm.option,
      center: {
        lat: this._mapView.center.latitude,
        lon: this._mapView.center.longitude
      },
      rotation: this._mapView.rotation,
      zoomLevel: this._mapView.zoom
    }
    this.store.dispatch(saveMapView(getExtendedAction(payload)))
  }
  //SHARED dashboard and map config
  /**
   * Handle home click, TODO consider integrating zooming and disabled states into the home vm display component.
   * @returns
   */
  handleHomeClick() {
    this._mapView.goTo(
      ArcGisHomeViewModel.getTargetGeometry(this),
      ArcGisHomeViewModel.getGoHomeOptions(this)
    )
    this._mapView.zoom = ArcGisHomeViewModel.getSchoolDtoZoom(this)
  }
  /** This function adds the ability to specify the level of zoom in and out. */
  handleZoomOutClick() {
    if (this._zoomVm?.canZoomOut) {
      this._zoomVm.zoomOut()
    } else {
      console.warn(`Can't zoom out, this._zoomVm?.canZoomOut`, this._zoomVm?.canZoomOut)
    }
  }
  /**  */
  handleZoomInClick() {
    if (this._zoomVm?.canZoomIn) {
      this._zoomVm.zoomIn()
    } else {
      console.warn(`Can't zoom in, this._zoomVm?.canZoomIn`, this._zoomVm?.canZoomIn)
    }
  }
  setPressedPollId = (
    payload: string | null,
    state: DashboardPageState,
    schoolOwlState: SchoolStateEnum | null
  ) => {
    ArcGisPollResponseLocGraphicHandler.pollResponsesVisibilityHandler(
      this,
      payload,
      state,
      schoolOwlState
    )
  }
  toggleDimmedAttackAlert(payload: boolean) {
    if (this._dimmedAlertGraphicsLayer) {
      this._dimmedAlertGraphicsLayer.visible = payload
    }
  }
  log = (m: string, o: Object | null = {}) => {
    this.loggingService.logLocally(m, OwlLoggingArea.MAP, OwlLogLevels.VERBOSE)
    if (o) {
      this.loggingService.logLocally(o, OwlLoggingArea.MAP, OwlLogLevels.VERBOSE)
    }
  }
}
