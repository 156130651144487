import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import { MobileUserTypes } from '@model/user/user.model'
import { MapPageType } from '@service/map/map.component.service.model'
import { ArcGisMapService, LayerIdToTypeLookup } from '../arcgis-map.service'
import {
  IMapUiControlSessionSettings,
  MapUiControlEnum
} from '@view/pages/dashboard-page/dashboard-page.view'

export class ArcgisLayersHandler {
  /**
   *  Handles the session storage settings for the map ui controls by setting the associated layer visibility
   * @param context  The context of the map service
   * @param settings  The settings to save
   */
  static handleSessionSavedSettings = (
    context: ArcGisMapService,
    settings: IMapUiControlSessionSettings
  ) => {
    Object.entries(settings).forEach(([key, value]) => {
      let layer: __esri.Layer | undefined
      switch (key) {
        case MapUiControlEnum.showStudents:
          if (context._studentLocationLayer) {
            layer = context._studentLocationLayer
          }
          break
        case MapUiControlEnum.showTeachers:
          if (context._teacherLocationLayer) {
            layer = context._teacherLocationLayer
          }
          break
        case MapUiControlEnum.showOtherStaff:
          if (context._otherStaffLocationLayer) {
            layer = context._otherStaffLocationLayer
          }
          break
        case MapUiControlEnum.showGuests:
          if (context._guestLocationLayer) {
            layer = context._guestLocationLayer
          }
          break
        case MapUiControlEnum.showAreaPanel:
          // No arc gis map handler needed for area panel
          break
        case MapUiControlEnum.showOldAttackAlerts:
          if (context._dimmedAlertGraphicsLayer) {
            layer = context._dimmedAlertGraphicsLayer
          }
          break
        case MapUiControlEnum.showSubAreas:
          if (context._schoolAreaLayer && context.type !== MapPageType.schoolMapConfig) {
            layer = context._schoolAreaLayer
          }
          break
        default:
          console.warn(`Unknown key ${key} with value ${value}`)
      }
      // console.log(`Setting layer ${key} to ${value}`)
      if (layer) {
        layer.visible = value
      }
    })
  }

  /** Builds all the needed layers based on the type of map usage dash versus config */
  static getLayersBasedOnType = (
    context: ArcGisMapService
  ): __esri.CollectionProperties<__esri.LayerProperties> => {
    // console.log(`context type is ${context.type}`)

    context._schoolBoundaryProximityLayer = new GraphicsLayer()
    context._schoolBoundaryLayer = new GraphicsLayer()
    context._schoolAreaLayer = new GraphicsLayer()

    //Recent Messages
    context._studentRecentMessageLayer = new GraphicsLayer()
    context._teacherRecentMessageLayer = new GraphicsLayer()
    context._otherStaffRecentMessageLayer = new GraphicsLayer()
    context._guestRecentMessageLayer = new GraphicsLayer()

    context._studentMessageLocationLayer = new GraphicsLayer()
    context._teacherMessageLocationLayer = new GraphicsLayer()
    context._otherStaffMessageLocationLayer = new GraphicsLayer()
    context._guestMessageLocationLayer = new GraphicsLayer()

    context._dimmedAlertGraphicsLayer = new GraphicsLayer()
    context._attackAlertGraphicsLayer = new GraphicsLayer()
    context._medialAlertGraphicsLayer = new GraphicsLayer()
    context._threatLocationLayer = new GraphicsLayer()
    context._pulsingAlertGraphicsLayer = new GraphicsLayer()

    context._studentLocationLayer = new GraphicsLayer()
    context._teacherLocationLayer = new GraphicsLayer()
    context._otherStaffLocationLayer = new GraphicsLayer()
    context._guestLocationLayer = new GraphicsLayer()

    context._guestPollResponsesLayer = new GraphicsLayer()
    context._otherStaffPollResponsesLayer = new GraphicsLayer()
    context._studentPollResponsesLayer = new GraphicsLayer()
    context._teacherPollResponsesLayer = new GraphicsLayer()

    // TODO research adding clustering to location layers
    // const addClustering = (l: GraphicsLayer): void => {
    // l.featureReduction = {
    //   type: "cluster",
    //   clusterRadius: "120px",
    //   popupTemplate: {
    //     content: "This cluster represents <b>{cluster_count}</b> features.",
    //     fieldInfos: [{
    //       fieldName: "cluster_count",
    //       format: {
    //         digitSeparator: true,
    //         places: 0
    //       }
    //     }]
    //   }
    // };
    // }

    // Create a lookup so we know which layer hit test results occur on
    context._layerTypeToIdLookup = {
      [LayerIdToTypeLookup.area]: context._schoolAreaLayer.id,
      [LayerIdToTypeLookup.studentLocation]: context._studentLocationLayer.id,
      [LayerIdToTypeLookup.guestLocation]: context._guestLocationLayer.id,
      [LayerIdToTypeLookup.teacherLocation]: context._teacherLocationLayer.id,
      [LayerIdToTypeLookup.otherStaffLocation]: context._otherStaffLocationLayer.id,
      [LayerIdToTypeLookup.pulsingAlert]: context._pulsingAlertGraphicsLayer.id,
      [LayerIdToTypeLookup.medicalAlert]: context._medialAlertGraphicsLayer.id,
      [LayerIdToTypeLookup.dimmedAlert]: context._dimmedAlertGraphicsLayer.id,
      [LayerIdToTypeLookup.attackAlert]: context._attackAlertGraphicsLayer.id
    }
    if (context.type === MapPageType.schoolDashboard) {
      // Note stacking order is important here as the last layer will render last
      return [
        // school areas
        context._schoolBoundaryLayer,
        context._schoolAreaLayer,

        // Location of communications
        // chat messages location
        context._studentMessageLocationLayer,
        context._teacherMessageLocationLayer,
        context._otherStaffMessageLocationLayer,
        context._guestMessageLocationLayer,

        // Explamation circle and pulsing red circles
        // alerts
        context._dimmedAlertGraphicsLayer,
        context._attackAlertGraphicsLayer,
        context._medialAlertGraphicsLayer,
        context._pulsingAlertGraphicsLayer,
        context._threatLocationLayer,

        // User Locations - dots with color by user type
        //Location - must be rendered on top so you can always interact with them via mouse hover and click - with priority ordering within the 4 types
        context._guestLocationLayer,
        context._studentLocationLayer,
        context._otherStaffLocationLayer,
        context._teacherLocationLayer,

        // User Recent Message Indicator - indicator next to user location
        context._studentRecentMessageLayer,
        context._teacherRecentMessageLayer,
        context._otherStaffRecentMessageLayer,
        context._guestRecentMessageLayer,

        // poll response location
        context._studentPollResponsesLayer,
        context._teacherPollResponsesLayer,
        context._otherStaffPollResponsesLayer,
        context._guestPollResponsesLayer
      ]
    } else if (context.type === MapPageType.schoolMapConfig && context._uploadedImageLayer) {
      return [
        context._schoolBoundaryProximityLayer,
        context._schoolBoundaryLayer,
        context._schoolAreaLayer
      ]
    } else {
      return []
    }
  }
  static getMessageLocationLayers = (context: ArcGisMapService) => {
    return [
      context._studentMessageLocationLayer,
      context._teacherMessageLocationLayer,
      context._otherStaffMessageLocationLayer,
      context._guestMessageLocationLayer
    ]
  }
  static getPollResponseLocationLayers = (context: ArcGisMapService) => {
    return [
      context._studentPollResponsesLayer,
      context._teacherPollResponsesLayer,
      context._otherStaffPollResponsesLayer,
      context._guestPollResponsesLayer
    ]
  }

  static getUserLocationLayser = (context: ArcGisMapService) => {
    return [
      context._studentLocationLayer,
      context._teacherLocationLayer,
      context._otherStaffLocationLayer,
      context._guestLocationLayer
    ]
  }
  static getUserLocationLayersWithType = (
    context: ArcGisMapService
  ): [GraphicsLayer | undefined, MobileUserTypes][] => {
    return [
      [context._studentLocationLayer, MobileUserTypes.student],
      [context._teacherLocationLayer, MobileUserTypes.teacher],
      [context._otherStaffLocationLayer, MobileUserTypes.otherStaff],
      [context._guestLocationLayer, MobileUserTypes.guest]
    ]
  }
  static getAllRecentMessageIndicatorLayers = (context: ArcGisMapService) => {
    return [
      context._studentRecentMessageLayer,
      context._teacherRecentMessageLayer,
      context._otherStaffRecentMessageLayer,
      context._guestRecentMessageLayer
    ]
  }
  static getAllRecentMessageIndicatorLayersWithType = (
    context: ArcGisMapService
  ): [GraphicsLayer | undefined, MobileUserTypes][] => {
    return [
      [context._studentRecentMessageLayer, MobileUserTypes.student],
      [context._teacherRecentMessageLayer, MobileUserTypes.teacher],
      [context._otherStaffRecentMessageLayer, MobileUserTypes.otherStaff],
      [context._guestRecentMessageLayer, MobileUserTypes.guest]
    ]
  }
}
