import { instructionsToDisplayMock } from '@mock/instructions.mock'
import { MobileUserTypes } from '@model/user/user.model'

export enum InstructionsPageText {
  title = 'title',
  subtitle = 'subtitle',
  topSectionStandardSubtext = 'topSectionStandardSubtext',
  topSectionEmptyStandard = 'topSectionEmptyStandard',
  topSectionBtn = 'topSectionBtn',
  revertModalTitle = 'revertModalTitle',
  revertModalSubtitle = 'revertModalSubtitle',
  revertModalConfirmBtn = 'revertModalConfirmBtn',
  warningModalTitle = 'warningModalTitle',
  warningModalText = 'warningModalText',
  warningModalConfirmBtn = 'warningModalConfirmBtn',
  editInstructionFormTitle = 'editInstructionFormTitle',
  editInstructionFormSubtitle = 'editInstructionFormSubtitle',
  editInstructionFormSubmitBtn = 'editInstructionFormSubmitBtn',
  updateInstructionTitleLabel = 'updateInstructionTitleLabel',
  updateInstructionContentLabel = 'updateInstructionContentLabel'
}

export const InstructionsPageTextString: Record<InstructionsPageText, string> = {
  [InstructionsPageText.title]: 'Instructions',
  [InstructionsPageText.subtitle]: 'Review and edit the contents of pre-defined instructions.',
  [InstructionsPageText.topSectionStandardSubtext]: 'Based on the',
  [InstructionsPageText.topSectionEmptyStandard]: 'Standard loading error',
  [InstructionsPageText.topSectionBtn]: 'Select Standard',
  [InstructionsPageText.revertModalTitle]: 'Select Standard',
  [InstructionsPageText.revertModalSubtitle]: 'Select a standard to use for instructions.',
  [InstructionsPageText.revertModalConfirmBtn]: 'Select',
  [InstructionsPageText.warningModalTitle]: 'Warning',
  [InstructionsPageText.warningModalText]:
    'Selecting the standard will erase any edits you made and will change the instructions sent to people in the school. Proceed?',
  [InstructionsPageText.warningModalConfirmBtn]: 'Proceed',
  [InstructionsPageText.editInstructionFormTitle]: 'Edit Instruction',
  [InstructionsPageText.editInstructionFormSubtitle]:
    'Enter new instruction content to send to OWL mobile users for the situation that the title describes.',
  [InstructionsPageText.editInstructionFormSubmitBtn]: 'Save Updates',
  [InstructionsPageText.updateInstructionTitleLabel]: 'Instruction title',
  [InstructionsPageText.updateInstructionContentLabel]: 'Instruction content for'
}

export type InstructionsVm = {
  id: number
  title: string
  content: InstructionsMessageVm[]
  isModified: boolean
  isSpecialForStudents: boolean
}

export type InstructionsMessageVm = {
  id: number
  predefinedGroupId: number | null
  text: string
  userType: MobileUserTypes | null
}

export type InstructionsStandardVm = {
  id: number
  name: string
  description: string
  default: boolean
}

export interface IShowUpdateInstructionsUiPayload {
  show: boolean
  instructionsVm: InstructionsVm | null
}

export const instructionsTableDisplayedColumns = ['title', 'content', 'menu']

export class InstructionsPageViewModel {
  instructionsToDisplay: InstructionsVm[] = instructionsToDisplayMock

  instructionsStandards: InstructionsStandardVm[] = []
  currentStandard: InstructionsStandardVm | null = null
  instructionsStandardIdToUpdate: number | null = null

  showUpdateInstructionsUiPayload: IShowUpdateInstructionsUiPayload = {
    show: false,
    instructionsVm: null
  }
  currentFormValue: any = {}
}
