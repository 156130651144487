import { ExtendedAction } from '@action/extended-ngrx-action'
import { IUserFormValue } from '@model/user/create-update-user.model'
import { SetRosterUsersByTabPayload } from '@model/user/set-roster-users-by-tab.payload'
import {
  MobileUserTypes,
  PatchUserDtoType,
  PostUserDtoType,
  UploadRosterUserDto
} from '@model/user/user.model'
import { createAction, props } from '@ngrx/store'
import { RosterFileUploadError } from '@view/file/roster-file.view'
import {
  IShowCreateUpdateUiPayload,
  RosterTabs,
  RosterValidationDto
} from '@view/pages/roster-page/roster-page.view'
import { UpdateRosterPayload } from './roster-api.actions'

export enum RosterPageActions {
  //Tab Selection
  handleSelectedRosterTab = '[RosterPageActions] Set Selected Roster Tab',
  handleGetUsersByUserTypeLookup = '[RosterPageActions] Set Users By User Type Lookup',

  //Set Dto in State
  setRosterValidationDto = '[RosterPageActions] Set Roster Validation Dto',
  getInvitationSummaryDtoByUserTypeSuccess = '[RosterPageActions] Get Invitation Summary Dto By User Type',

  //Loading Indicators
  setRosterTabLoadingState = '[RosterPageActions] Set Roster Tab Loading State',
  setRosterPageSpinnerState = '[RosterPageActions] Set Roster Page Spinner State',

  handleSelectedUserId = '[RosterPageActions] Set Selected User Id',
  //Invitations
  getInvitationDataByCurrentType = '[RosterPageActions] Get Invitation Data By Current Type',
  triggerReinviteUnregistered = '[RosterPageActions] Re-invite Unregistered',
  handleSendInviteSelectedUser = '[RosterPageActions] Send Invite Selected User',

  //Deactivate User
  handleDeactivateSelectedUser = '[RosterPageActions] Deactivate Selected User',
  toggleShowDeactivateModal = '[RosterPageActions] Toggle Show Deactivate Modal',

  //File Upload
  setIsRosterValidationMode = '[RosterPageActions] Set should the Preview Mod be shown.',
  updateRoster = '[RosterPageActions] Update Roster',
  validateRoster = '[RosterPageActions] Validate Roster',
  handleUploadRosterBrowserError = '[RosterPageActions] Handle Upload Error',

  // User Actions
  showAddUserUi = '[RosterPageActions] Show Add User Ui',
  createAndInviteUser = '[ RosterPageActions] Create And Invite User',
  updateUser = '[ RosterPageActions] Update User',
  closeAddEditUserUi = '[RosterPageActions] Close Add Edit User Ui',
  updateUserFormValue = '[RosterPageActions] Update User Form Value',

  //Page Load
  getInvitationSummaryDtoByMobileType = '[RosterApiActions] Get Invitation Summary Dto By Type',
  showCompleteRosterModal = '[RosterPageActions] Show Complete Roster Modal'
}
export const getInvitationSummaryDtoByMobileType = createAction(
  RosterPageActions.getInvitationSummaryDtoByMobileType,
  props<ExtendedAction<MobileUserTypes>>()
)

export const setIsRosterValidationMode = createAction(
  RosterPageActions.setIsRosterValidationMode,
  props<ExtendedAction<boolean>>()
)
export const setRosterValidationDto = createAction(
  RosterPageActions.setRosterValidationDto,
  props<ExtendedAction<RosterValidationDto>>()
)
/** Sending a roster tab sets it to showing the spinner in the related vm, if null it sets all tab spinners to not loading. */
export interface TabVmLoadingStatePayload {
  /** If this has a value the new tab vm will use is loading for loading indication, if it's null all tabs will be set to not loading. */
  tab: RosterTabs | null
  /** This value is ignored if tab is null and update is assumed to be to false. */
  isLoading?: boolean
}
export const setRosterTabLoadingState = createAction(
  RosterPageActions.setRosterTabLoadingState,
  props<ExtendedAction<TabVmLoadingStatePayload>>()
)
export const handleInvitationSummaryDtyByUserTypeSuccess = createAction(
  RosterPageActions.getInvitationSummaryDtoByUserTypeSuccess,
  props<ExtendedAction<MobileUserTypes>>()
)

export const toggleShowDeactivateModal = createAction(RosterPageActions.toggleShowDeactivateModal)

export const setRosterPageSpinnerState = createAction(
  RosterPageActions.setRosterPageSpinnerState,
  props<ExtendedAction<boolean>>()
)

export const handleSelectedRosterTab = createAction(
  RosterPageActions.handleSelectedRosterTab,
  props<ExtendedAction<RosterTabs>>()
)

export const handleGetUsersByUserTypeLookup = createAction(
  RosterPageActions.handleGetUsersByUserTypeLookup,
  props<ExtendedAction<SetRosterUsersByTabPayload>>()
)

export const handleSelectedUserId = createAction(
  RosterPageActions.handleSelectedUserId,
  props<ExtendedAction<number>>()
)
export const handleDeactivateSelectedUser = createAction(
  RosterPageActions.handleDeactivateSelectedUser
)

export const handleSendInviteSelectedUser = createAction(
  RosterPageActions.handleSendInviteSelectedUser
)

//invitation summary
export const getInvitationDataByCurrentType = createAction(
  RosterPageActions.getInvitationDataByCurrentType
)

//File Upload
export const validateRoster = createAction(
  RosterPageActions.validateRoster,
  props<ExtendedAction<UploadRosterUserDto[]>>()
)
export const updateRoster = createAction(
  RosterPageActions.updateRoster,
  props<ExtendedAction<UpdateRosterPayload>>()
)

//reinvite unregistered
export interface TriggerReinvitePayload {
  mobileUserType: MobileUserTypes
  tab: RosterTabs
}
export const triggerReinviteUnregistered = createAction(
  RosterPageActions.triggerReinviteUnregistered,
  props<ExtendedAction<TriggerReinvitePayload>>()
)

//CREATE AND UPDATE USER
export const showCreateUpdateUserUi = createAction(
  RosterPageActions.showAddUserUi,
  props<ExtendedAction<IShowCreateUpdateUiPayload>>()
)
export const createAndInviteUser = createAction(
  RosterPageActions.createAndInviteUser,
  props<ExtendedAction<PostUserDtoType>>()
)
export const updateUser = createAction(
  RosterPageActions.updateUser,
  props<ExtendedAction<PatchUserDtoType>>()
)
export const updateCreateUpdateUserForm = createAction(
  RosterPageActions.updateUserFormValue,
  props<ExtendedAction<IUserFormValue>>()
)

export const handleUploadRosterBrowserError = createAction(
  RosterPageActions.handleUploadRosterBrowserError,
  props<ExtendedAction<RosterFileUploadError | null>>()
)

export const showCompleteRosterModal = createAction(RosterPageActions.showCompleteRosterModal)
