import { getExtendedAction } from '@action/extended-ngrx-action'
import {
  createAndInviteUser,
  getInvitationSummaryDtoByMobileType,
  handleDeactivateSelectedUser,
  handleGetUsersByUserTypeLookup,
  handleSelectedRosterTab,
  handleSelectedUserId,
  handleSendInviteSelectedUser,
  handleUploadRosterBrowserError,
  setIsRosterValidationMode,
  showCompleteRosterModal,
  showCreateUpdateUserUi,
  toggleShowDeactivateModal,
  TriggerReinvitePayload,
  triggerReinviteUnregistered,
  updateCreateUpdateUserForm,
  updateRoster,
  updateUser
} from '@action/roster-page/roster-page.actions'
import { Injectable } from '@angular/core'
import { ButtonIconPlacement } from '@component/shared/button/button.component.view'
import { UserFormVariants } from '@model/user/create-update-user.model'
import {
  GetUserDto,
  MobileUserTypes,
  PatchUserDtoType,
  PostMobileUserDto,
  PostWebUserDto,
  UploadRosterUserDto
} from '@model/user/user.model'
import { Store } from '@ngrx/store'
import { selectLoggedInUserId } from '@selector/auth.selector'
import {
  createUpdateUserHasError,
  createUpdateUserIsLoading,
  reinviteUnregisteredLoading,
  selectCreateUpdateUserErrorText,
  selectRosterFileUploadBrowserError,
  selectRosterTabToVmLookup,
  selectSelectedRosterTab,
  selectSelectedUserTypeUsersExist,
  selectServerValidations,
  selectSetRosterUsersByTabPayload
} from '@selector/page/roster-page/roster-page.selector'
import { selectSummaryCardIsVisible } from '@selector/page/roster-page/roster-summary.selector'
import { selectTableDisplayedColumns } from '@selector/page/roster-page/roster-table.selectors'
import {
  selectReinviteButtonTooltipText,
  selectUploadButtonText
} from '@selector/page/roster-page/roster-top-section.selector'
import {
  selectRosterTopSectionSubTitle,
  selectRosterTopSectionTitle
} from '@selector/page/roster-page/roster-top-section.selectors'
import {
  isProcessingInvitationForCurrentUserType,
  selectCanShowCreateUserButton,
  selectCreateUpdateUserFormPayload,
  selectCreateUpdateUserUiIsVisible,
  selectDynamicAddUserString,
  selectDynamicTooltipAddUserString,
  selectInviteSummaryDtoForCurrentTab,
  selectIsPreviewMod,
  selectIsSelectedMobileUserType,
  selectRosterPageSpinnerState,
  selectRosterUserVmsForSelectedTab,
  selectRosterValidationIsValid,
  selectRosterValidationSummaryDtoIfIsPreview,
  selectRosterValidationUserDtos,
  selectSelectedMobileUserTypeForTab,
  selectSelectedTabUserCount,
  selectShowDeactivateMw,
  selectShowReinviteButton,
  selectShowRosterPageSpinnerState
} from '@selector/page/roster-page/roster-view.selector'
import { selectPageIsRoster } from '@selector/route.selector'
import { AppComponentService } from '@service/app.component.service'
import { OwlLoggingArea, OwlLogLevels } from '@service/logging/logging.model'
import { LoggingService } from '@service/logging/logging.service'
import {
  IRosterFooterButtonVm,
  IRosterTabButtonViewModel,
  IRosterTableActionButtonVm,
  IShowCreateUpdateUiPayload,
  RosterImageNames,
  RosterPageViewModel,
  RosterTabs,
  RosterTabToMobileUserType,
  RosterTabToTabVmLookup,
  RosterTabViewModel
} from '@view/pages/roster-page/roster-page.view'
import { IRosterUserVm } from '@view/pages/roster-page/roster-table-user.view.model'
import { map, Observable, of, Subscription, tap } from 'rxjs'

@Injectable()
export class RosterPageComponentService {
  /** Used in init once roster page is ready, i.e. we have users in global auth state for the school, and we've fetched all the invitation summaries for all the user types. Then we can provide the users to be aggregated by tab and anything else needing to be added later. */
  setRosterUsersByTabPayload$ = this.store.select(selectSetRosterUsersByTabPayload)
  rosterPageSpinnerState$ = this.store.select(selectRosterPageSpinnerState)
  canShowRosterPage$ = this.store.select(selectRosterPageSpinnerState).pipe(map((state) => !state))

  loggedInBrowserUserId$ = this.store.select(selectLoggedInUserId)

  /** This shuld be refactored to use selected tab. */
  selectSelectedUserTypeUsersExist$ = this.store.select(selectSelectedUserTypeUsersExist)
  /** This will be null if the selected tab is not a mobile user related tab. This shuld be refactored to use selected tab.*/
  selectSelectedUserType$ = this.store.select(selectSelectedMobileUserTypeForTab)

  //TOP SECTION
  rosterTopSectionTitle$ = this.store.select(selectRosterTopSectionTitle)
  rosterTopSectionSubTitle$ = this.store.select(selectRosterTopSectionSubTitle)
  selectPageIsRoster$ = this.store.select(selectPageIsRoster)

  //TABLE SECTION
  tableDisplayedColumns$ = this.store.select(selectTableDisplayedColumns)
  selectTabVmLookup$ = this.store.select(selectRosterTabToVmLookup)
  selectedTab$ = this.store.select(selectSelectedRosterTab)
  selectedTabCount$ = this.store.select(selectSelectedTabUserCount)
  // inviteSummaryErrorsByUserIdLookup$ = this.store.select(selectUserInvitationErrorByUserIdLookup)

  selectIsPreviewMod$ = this.store.select(selectIsPreviewMod)
  reinviteUnregisteredLoading$ = this.store.select(reinviteUnregisteredLoading)
  selectShowDeactivateMw$ = this.store.select(selectShowDeactivateMw)

  // TODO Remove global spinner usage in page level component - global means outside of a page context.
  selectShowGlobalSpinner$ = this.store.select(selectShowRosterPageSpinnerState)

  // selectUsersFromPreview$ = this.store.select(selectTabUserRosterVmsFromValidationDto)

  //Preview of upload validations
  rosterValidationSummaryDtoIfIsPreview$ = this.store.select(
    selectRosterValidationSummaryDtoIfIsPreview
  )

  //Summary
  summaryCardIsVisible$ = this.store.select(selectSummaryCardIsVisible)
  inviteSummaryDtoForCurrentTab$ = this.store.select(selectInviteSummaryDtoForCurrentTab)

  // Validation related selectors
  selectIsValidPreviewData$ = this.store.select(selectRosterValidationIsValid)

  /** Collection of roster user vms for currently selected tab, defaults in page vm construction. */
  rosterUserVmsForSelectedTab$ = this.store.select(selectRosterUserVmsForSelectedTab)

  /** This is a way to get to the vm if we know the roster tab we're working with. */
  rosterTabToVmLookup: RosterTabToTabVmLookup | null = null
  /** Used to update rosterTabToVmLookup when the tab vm changes. */
  selectRosterTabToVmLookup$ = this.store.select(selectRosterTabToVmLookup)

  isProcessingInvitationForCurrentUserType$ = this.store.select(
    isProcessingInvitationForCurrentUserType
  )

  //FOOTER SECTION
  selectIsSelectedMobileUserType$ = this.store.select(selectIsSelectedMobileUserType)
  selectUploadError$ = this.store.select(selectRosterFileUploadBrowserError)
  reinviteButtonTooltipText$ = this.store.select(selectReinviteButtonTooltipText)
  selectShowReinviteButton$ = this.store.select(selectShowReinviteButton)
  // upload roster
  rosterValidationUserDtos$ = this.store.select(selectRosterValidationUserDtos)
  uploadButtonText$ = this.store.select(selectUploadButtonText)

  //USER
  createUpdateUserFormPayload$ = this.store.select(selectCreateUpdateUserFormPayload)
  /** Debounce loading time to show spinner for min amount of time. */
  createUpdateUserIsLoading$ = this.store.select(createUpdateUserIsLoading)
  createUpdateUserHasError$ = this.store.select(createUpdateUserHasError)
  createUpdateUserErrorText$ = this.store.select(selectCreateUpdateUserErrorText)
  selectCreateUpdateUserUiIsVisible$ = this.store.select(selectCreateUpdateUserUiIsVisible)
  dynamicAddUserString$ = this.store.select(selectDynamicAddUserString)
  dynamicToolTipUserString$ = this.store.select(selectDynamicTooltipAddUserString)
  canShowCreateUserButton$ = this.store.select(selectCanShowCreateUserButton)
  serverValidations$ = this.store.select(selectServerValidations)

  subs: Subscription[] = []

  /** Sync ref useful for when dispatching a submit roster action. */
  usersToUpload?: UploadRosterUserDto[] = []
  /** Selected tab drives which view models are visible. */
  selectedTab: RosterTabs | null = null
  constructor(
    private store: Store,
    public appCompServ: AppComponentService,
    public loggingService: LoggingService
  ) {}

  /** For now we need to make a call to get invitation information by type. */
  getUserInvitesByType = (userType: MobileUserTypes | null) => {
    if (userType) {
      this.store.dispatch(getInvitationSummaryDtoByMobileType(getExtendedAction(userType)))
    }
  }

  init = () => {
    Object.values(RosterTabToMobileUserType).forEach(this.getUserInvitesByType)
    this.subs = [
      this.selectedTab$.subscribe((tab) => (this.selectedTab = tab)),
      this.setRosterUsersByTabPayload$
        .pipe(
          tap((payload) => {
            if (payload) {
              // console.log('Set users by roster tab payload ready, roster page ready to show.')
              this.store.dispatch(handleGetUsersByUserTypeLookup(getExtendedAction(payload)))
            } else {
              this.warn('No payload in roster page service.')
            }
          })
        )
        .subscribe(),
      this.appCompServ.selectClientSideAdditionOfAtLeastOneUser$.subscribe((showModal) => {
        if (showModal) {
          this.store.dispatch(showCompleteRosterModal())
        }
      }),
      this.rosterValidationUserDtos$
        .pipe(
          tap((users) => {
            this.usersToUpload = users
          })
        )
        .subscribe()
    ]
  }
  destroy = () => {
    this.subs.forEach((s) => (!s.closed ? s.unsubscribe() : null))
  }

  toggleShowDeactivateModal = () => {
    this.store.dispatch(toggleShowDeactivateModal())
  }

  handleSelectTab = (rosterTab: RosterTabs) => {
    this.store.dispatch(handleSelectedRosterTab(getExtendedAction(rosterTab)))
  }

  handleSelectedUserId = (id: number) => {
    this.store.dispatch(handleSelectedUserId(getExtendedAction(id)))
  }

  handleDeactivateSelectedUser = () => {
    this.store.dispatch(handleDeactivateSelectedUser())
  }

  handleSendInviteSelectedUser = () => {
    this.store.dispatch(handleSendInviteSelectedUser())
  }

  /** We need to be able to return the tab vm from the lookup. */
  getTabVmFromLoookup = (
    lookup: RosterTabToTabVmLookup,
    tab: RosterTabs
  ): RosterTabViewModel | null => {
    if (!lookup) {
      // console.warn('selectUserCountByType: selectedTabVmLookup is null')
      return null
    }
    return lookup[tab]
  }
  /** We need to be able to return the tab vm from the lookup. */
  getCountBySelectedTab = (lookup: RosterTabToTabVmLookup, selectedTab: RosterTabs): number => {
    const tabVm = this.getTabVmFromLoookup(lookup, selectedTab)
    if (!tabVm) {
      // console.warn('selectUserCountByType: selectedTabVmLookup is null')
      return 0
    }
    return tabVm?.userVms.length ?? 0
  }
  /** Each button vm needs a callback with a tab value to be able to get the count of users in the tab. */
  getUserCountByTabObservable = (tab: RosterTabs): Observable<number> => {
    return this.selectTabVmLookup$.pipe(map((lookup) => this.getCountBySelectedTab(lookup, tab)))
  }
  /** Logic to determine which tab is selected */
  getTabIsSelectedObservable = (tab: RosterTabs): Observable<boolean> => {
    return this.selectedTab$.pipe(map((selectedTab) => selectedTab === tab))
  }
  /**
   * Logic to determine if the tab should display in process spinner.
   */
  getTabIsProcessingObservable = (tab: RosterTabs): Observable<boolean> => {
    return this.selectTabVmLookup$.pipe(map((lookup) => lookup[tab]?.isInProcess ?? false))
  }
  // TODO Clarify the design requirements for when the error should actually be hidden, seem to hide even with tab change which seems wrong.
  hideError = () => {
    this.store.dispatch(handleUploadRosterBrowserError(getExtendedAction(null)))
  }

  /** This checks to make sure that the selected tab is for a specific mobile type and dispatches a api call trigger action. */
  handleReinvite = () => {
    if (!this.selectedTab) {
      console.warn("can't trigger reinvite in null tab.")
      return
    }
    const mobileType = RosterTabToMobileUserType[this.selectedTab]
    if (!mobileType) {
      console.warn("can't trigger reinvite in non mobile tab.")
      return
    }
    const payload: TriggerReinvitePayload = {
      mobileUserType: mobileType,
      tab: this.selectedTab
    }
    this.store.dispatch(triggerReinviteUnregistered(getExtendedAction(payload)))
  }

  // TABLE ACTION BUTTONS
  editActionButton: IRosterTableActionButtonVm = {
    text: 'Edit',
    imageName: RosterImageNames.editUser,
    cb: null
  }
  sendInviteActionButton: IRosterTableActionButtonVm = {
    text: 'Send Invite Email',
    imageName: RosterImageNames.reinviteUnregistered,
    cb: this.handleSendInviteSelectedUser
  }
  deactivateActionButton: IRosterTableActionButtonVm = {
    text: 'Deactivate',
    imageName: RosterImageNames.deactivate,
    cb: this.handleDeactivateSelectedUser
  }
  cancelActionButton: IRosterTableActionButtonVm = {
    text: 'Cancel',
    cb: this.toggleShowDeactivateModal
  }

  footerActionButtons: IRosterFooterButtonVm[] = [
    {
      text: 'Re-invite',
      imageName: RosterImageNames.reinviteUnregistered,
      iconPlacement: ButtonIconPlacement.left,
      cb: this.handleReinvite,
      //from rosterPageCompServ.reinviteButtonTooltipText$
      tooltipText: null
    },
    {
      text: 'Confirm',
      imageName: RosterImageNames.confirmUpload,
      iconPlacement: ButtonIconPlacement.left,
      cb: null,
      tooltipText: 'Confirm Upload'
    },
    {
      text: 'Upload',
      imageName: RosterImageNames.downloadSample,
      iconPlacement: ButtonIconPlacement.left,
      cb: null,
      tooltipText: 'Upload new roster'
    },
    {
      text: 'Cancel',
      cb: null,
      iconPlacement: null,
      tooltipText: 'Cancel Upload'
    }
  ]
  /** Each roster tab needs a button vm associated with it. */
  getRosterButtonVmFromTab = (tab: RosterTabs): IRosterTabButtonViewModel => {
    return {
      text: RosterPageViewModel.getTabButtonText(tab, true),
      imageName: RosterImageNames.plusOnABlueBackground,
      iconPlacement: ButtonIconPlacement.right,
      cb: () => {
        this.handleSelectTab(tab)
        this.hideError()
      },
      count$: this.getUserCountByTabObservable(tab),
      isSelected$: this.getTabIsSelectedObservable(tab),
      isLoadingInCountArea$: this.getTabIsProcessingObservable(tab)
    }
  }
  /** Set up dedicated interaface for roster table action buttons. */
  consolidatedRosterTab: IRosterTabButtonViewModel = {
    text: 'Show consolidated roster',
    imageName: RosterImageNames.nineWhiteDots,
    iconPlacement: ButtonIconPlacement.right,
    cb: () => {
      this.handleSelectTab(RosterTabs.consolidatedAllUsers)
      this.hideError()
    },
    // No count is shown it roster tab designs.
    count$: of(0),
    isSelected$: this.getTabIsSelectedObservable(RosterTabs.consolidatedAllUsers),
    // TODO Confirm if you should be able to trigger invite in consolidated view.
    isLoadingInCountArea$: of(false)
  }
  // Consolidated roster tab was designed in a different way so it needs a dedicated reference.
  tableTabButtons: IRosterTabButtonViewModel[] = Object.values(RosterTabs)
    .filter((type) => type !== RosterTabs.consolidatedAllUsers)
    .map(this.getRosterButtonVmFromTab)

  //USER
  dispatchNewFormValue = (formValue: any) => {
    this.store.dispatch(updateCreateUpdateUserForm(getExtendedAction(formValue)))
  }
  createUser = (dto: PostWebUserDto | PostMobileUserDto) => {
    this.store.dispatch(createAndInviteUser(getExtendedAction(dto)))
  }
  updateUser = (dto: Partial<GetUserDto>) => {
    this.store.dispatch(updateUser(getExtendedAction(dto as PatchUserDtoType)))
  }
  showAdd = () => {
    this.showAddEditUserUi(UserFormVariants.create)
  }
  showUpdate = (vm: IRosterUserVm) => {
    this.showAddEditUserUi(UserFormVariants.update, vm)
  }
  showAddEditUserUi = (userFormVariant: UserFormVariants, vm?: IRosterUserVm) => {
    if (!this.selectedTab) {
      console.warn('Dev error: no selected tab in roster page component service.')
      return
    }
    const payload: IShowCreateUpdateUiPayload = {
      show: true,
      userFormVariant,
      rosterUserVm: vm,
      tab: this.selectedTab
    }
    this.store.dispatch(showCreateUpdateUserUi(getExtendedAction(payload)))
  }
  hideAddEditUserUi = () => {
    if (!this.selectedTab) {
      console.warn('Dev error: no selected tab in roster page component service.')
      return
    }
    this.store.dispatch(
      showCreateUpdateUserUi(
        getExtendedAction({
          show: false,
          userFormVariant: null,
          tab: this.selectedTab
        })
      )
    )
  }

  handleConfirmUpload = () => {
    if (!this.selectedTab) {
      console.warn('DEV ERROR: No selected tab in roster page component service.')
      return
    }
    const mobileTypeForTab = RosterTabToMobileUserType[this.selectedTab]
    if (this.usersToUpload && this.selectedTab && mobileTypeForTab) {
      this.store.dispatch(
        updateRoster(
          getExtendedAction({
            users: this.usersToUpload,
            type: mobileTypeForTab
          })
        )
      )
    } else {
      console.warn("Can't handle upload in non mobile tab.")
    }
  }
  hidePreviewMod = () => {
    this.store.dispatch(setIsRosterValidationMode(getExtendedAction(false)))
  }
  warn = (message: string) => {
    this.loggingService.warnLocally(message, OwlLoggingArea.ROUTING, OwlLogLevels.VERBOSE)
  }
}
