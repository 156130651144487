import { Injectable } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormControlState,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms'
import { UserDtoHelper } from '@domain/dto-helpers/user-model.helper'
import { BaseFormModel } from '@model/base-form.model'
import {
  additionalEmailValidations,
  additionalPhoneValidations,
  AdditionalValidations,
  CustomFormValidators
} from '@model/shared/custom-form-validators.model'
import { firstNameDisplayString, lastNameDisplayString } from '@shared/constants'
import { FormUtils } from '@shared/form.util'
import { ExcludeFromTuple, ExcludeMultipleFromTuple, TupleToRecord } from '@shared/typescript.utils'
import { OwlUserTypes } from '@view/pages/roster-page/roster-page.view'
import { IRosterUserVm } from '../../view/pages/roster-page/roster-table-user.view.model'
import {
  GetUserDto,
  MobileUserTypes,
  PostMobileUserDto,
  PostUserDto,
  PostUserDtoType,
  PostWebUserDto,
  UserDtoProps,
  UserTypes
} from './user.model'

export type UserFormProps =
  | UserDtoProps.firstName
  | UserDtoProps.lastName
  | UserDtoProps.email
  | UserDtoProps.phone
  | UserDtoProps.mobileType
  | UserDtoProps.type

/** This list of props is used for the admin since they can have an optional mobile user type. */
export type CreateUpdateUserPropTuple = [
  UserDtoProps.firstName,
  UserDtoProps.lastName,
  UserDtoProps.email,
  UserDtoProps.phone,
  UserDtoProps.mobileType,
  UserDtoProps.type
]

export type PostMobileUserProps = ExcludeMultipleFromTuple<
  CreateUpdateUserPropTuple,
  [UserDtoProps.type, UserDtoProps.mobileType]
>

export type PatchMobileUserProps = ExcludeFromTuple<CreateUpdateUserPropTuple, UserDtoProps.type>
export type UserEditableProps =
  | CreateUpdateUserPropTuple
  | PostMobileUserProps
  | PatchMobileUserProps

export type UserPropsWithValidationType =
  | UserDtoProps.firstName
  | UserDtoProps.lastName
  | UserDtoProps.email
  | UserDtoProps.phone

export interface IUserFormValue {
  [UserDtoProps.firstName]: string
  [UserDtoProps.lastName]: string
  [UserDtoProps.email]: string
  [UserDtoProps.phone]: string
  [UserDtoProps.mobileType]: MobileUserTypes | null
  [UserDtoProps.type]: UserTypes | null
}
export type CreateUserFormType = TupleToRecord<UserFormProps, FormControl<string | null>>

export enum UserFormVariants {
  create = 'create',
  update = 'update'
}
export enum UserTypeVariants {
  mobile = 'mobile',
  web = 'web'
}
export type UserValidationProps = UserDtoProps & AdditionalValidations
export type ValidationMessages = {
  [K in UserValidationProps]: string
}

@Injectable()
export class CreateUpdateUserFormModel extends BaseFormModel<UserDtoProps> {
  errorMessages: Record<UserPropsWithValidationType, Partial<ValidationMessages>> = {
    [UserDtoProps.firstName]:
      CustomFormValidators.getTextInputAdditionalValidations(firstNameDisplayString),
    [UserDtoProps.lastName]:
      CustomFormValidators.getTextInputAdditionalValidations(lastNameDisplayString),
    [UserDtoProps.email]: additionalEmailValidations,
    [UserDtoProps.phone]: additionalPhoneValidations
  }
  /** Used during updates to compose the network call. */
  userId: number | null = null
  variant: UserFormVariants | null = null
  constructor(private fb: FormBuilder) {
    super()
  }
  getUserPropsByType = (variant: UserTypeVariants): UserEditableProps => {
    if (variant === UserTypeVariants.web) {
      return this.getUserPropsForWeb() as UserEditableProps
    } else if (variant === UserTypeVariants.mobile) {
      return this.getUserPropsForMobile() as UserEditableProps
    }
    // To avoid throwing an error here, override edge case to appease ts transpiler.
    return [] as unknown as UserEditableProps
  }
  getUserPropsForMobile = (): UserDtoProps[] => {
    if (this.variant === UserFormVariants.update) {
      // Order matters here, as this is the order in which props are added to the UI
      return [
        UserDtoProps.firstName,
        UserDtoProps.lastName,
        UserDtoProps.mobileType,
        UserDtoProps.email,
        UserDtoProps.phone
      ]
    }
    return [UserDtoProps.firstName, UserDtoProps.lastName, UserDtoProps.email, UserDtoProps.phone]
  }
  getUserPropsForWeb = (): UserDtoProps[] => {
    return [
      UserDtoProps.firstName,
      UserDtoProps.lastName,
      UserDtoProps.mobileType,
      UserDtoProps.email,
      UserDtoProps.phone
      // TODO Add ability to make someone a owner but this requires backend work because that implies the user is an owner and we'd remove them from ownership status on the server, and probably show a modal to explain that.
      // UserDtoProps.type
    ]
  }
  /** Gets network ready dto from from. */
  getCreateDto(): PostWebUserDto | PostMobileUserDto | null {
    const value = FormUtils.trimAllFormValues(this._form.value) as PostUserDto
    if (value[UserDtoProps.phone]) {
      value[UserDtoProps.phone] = FormUtils.getPhoneValueForDto(value[UserDtoProps.phone])
    }
    // Based on the form type, we need to add the mobile type or the web type.
    // We have to delete the property as the server will throw an error if we send both.
    if (value[UserDtoProps.mobileType] || value[UserDtoProps.type]) {
      return value as PostUserDtoType
    }
    return null
  }
  /** Gets network ready dto from form to update the user. */
  getUpdateDto(): Partial<GetUserDto> {
    const value = { ...(this._form.value as Partial<GetUserDto>), id: this.userId ?? 0 }
    if (value[UserDtoProps.phone]) {
      value[UserDtoProps.phone] = FormUtils.getPhoneValueForDto(value[UserDtoProps.phone])
    }
    return FormUtils.trimAllFormValues(value)
  }
  /** Creates a form for creating or updatings a mobile or web user by passed form type, user type, and dto. */
  private _createFormGroup = (
    formVariant: UserFormVariants,
    userType: OwlUserTypes | undefined,
    vm?: IRosterUserVm,
    loggedInUserDto?: GetUserDto
  ): Partial<Record<UserDtoProps, FormControl>> => {
    let userIsEditingThemSelves = false
    if (this.userIsEditingThemselves(loggedInUserDto, vm)) {
      userIsEditingThemSelves = true
    }
    this.variant = formVariant
    const isUpdateForm = formVariant === UserFormVariants.update
    const userMobileType: MobileUserTypes | undefined = vm?.getUserDto?.[UserDtoProps.mobileType]
    const isMobileType = UserDtoHelper.isMobileUser(userType)
    const isWebType = UserDtoHelper.isWebUser(userType)
    let userTypeVariant: UserTypeVariants | null = null
    if (isMobileType) {
      userTypeVariant = UserTypeVariants.mobile
    } else if (isWebType) {
      userTypeVariant = UserTypeVariants.web
    }
    if (!userTypeVariant) {
      throw Error(`User type variant is not supported for user type ${userType}`)
    }
    if (isUpdateForm) {
      this.userId = vm?.getUserDto?.id ?? 0
    } else {
      this.userId = null
    }
    return {
      [UserDtoProps.firstName]: this.fb.control<string>(
        isUpdateForm ? vm?.getUserDto?.[UserDtoProps.firstName] ?? '' : '',
        CustomFormValidators.getNameValidators(this.fb, 1, true)
      ),
      [UserDtoProps.lastName]: this.fb.control<string>(
        isUpdateForm ? vm?.getUserDto?.[UserDtoProps.lastName] ?? '' : '',
        CustomFormValidators.getNameValidators(this.fb, 1, true)
      ),
      [UserDtoProps.email]: this.fb.control<string>(
        isUpdateForm
          ? this.getUserEmailControlState(
              vm?.getUserDto?.[UserDtoProps.email],
              userIsEditingThemSelves
            ) ?? ''
          : '',
        this.getEmailValidaitonsByType(userTypeVariant)
      ),
      [UserDtoProps.phone]: this.fb.control<string>(
        isUpdateForm
          ? CustomFormValidators.getPhone(vm?.getUserDto?.[UserDtoProps.phone]) ?? ''
          : '',
        CustomFormValidators.getPhoneValidaitonsByType(userTypeVariant, userMobileType)
      ),
      [UserDtoProps.mobileType]: this.fb.control<MobileUserTypes | null>(
        isMobileType || userMobileType ? (userType as MobileUserTypes) : null,
        isMobileType ? Validators.required : undefined
      ),
      [UserDtoProps.type]: this.fb.control<UserTypes | null>(
        isWebType ? (userType as UserTypes) : null,
        isWebType ? Validators.required : undefined
      )
    }
  }
  getChangeObs() {
    return this._form.valueChanges
  }
  /** Create a form for a mobile user or a web user, and to either create the user or update. NOTE: Dto is required if update form. */
  public createForm = (
    formVariant: UserFormVariants,
    userType?: OwlUserTypes,
    vm?: IRosterUserVm,
    loggedInUserDto?: GetUserDto
  ) => {
    if (!userType) {
      console.error(`User type is required for create or update form`)
      return
    }
    this._form = this._createForm(formVariant, userType, vm, loggedInUserDto)
  }
  private _createForm = (
    formVariant: UserFormVariants = UserFormVariants.create,
    userType: OwlUserTypes = MobileUserTypes.teacher,
    vm?: IRosterUserVm,
    loggedInUserDto?: GetUserDto
  ): FormGroup => {
    if (formVariant === UserFormVariants.update && !vm) {
      console.error(`Dto is required for update form, for user type ${userType}`)
    }
    // TODO Issue where vm is mangled and doesn't have first and last name.
    return this.fb.group(this._createFormGroup(formVariant, userType, vm, loggedInUserDto))
  }
  /** Emails are required for web app users but not for mobile users. */
  getEmailValidaitonsByType = (variant: UserTypeVariants): ValidatorFn[] => {
    if (variant === UserTypeVariants.web) {
      return [Validators.required, CustomFormValidators.trimmedEmailValidator(this.fb)]
    }
    if (variant === UserTypeVariants.mobile) {
      return [CustomFormValidators.trimmedEmailValidator(this.fb)]
    }
    return []
  }
  /** Form control must be disabled if user is trying to edit their own account. */
  getUserEmailControlState(
    email: string | undefined,
    userIsEditingThemSelves: boolean
  ): FormControlState<string> {
    return {
      value: email ?? '',
      disabled: userIsEditingThemSelves
    }
  }
  /** We only check to make sure the user isn't editing their specific user record. */
  userIsEditingThemselves = (
    loggedInUserDto: GetUserDto | undefined,
    vm: IRosterUserVm | undefined
  ): boolean => {
    return (
      vm?.getUserDto?.[UserDtoProps.email] === loggedInUserDto?.[UserDtoProps.email] &&
      vm?.getUserDto?.[UserDtoProps.id] === loggedInUserDto?.[UserDtoProps.id]
    )
  }
  isMobile = (t: OwlUserTypes | undefined) => {
    return UserDtoHelper.isMobileUser(t)
  }
  isWeb = (t: OwlUserTypes | undefined) => {
    return UserDtoHelper.isWebUser(t)
  }
  _form: FormGroup = this._createForm()
}
